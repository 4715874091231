<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import axios from "axios";
// import Swal from "sweetalert2";
import { ref } from "vue";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Soal Kompetensi Menjodohkan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  setup() {
    const items = ref([
      { id: 0, title: "Jawaban 1", list: 1 },
      { id: 1, title: "Jawaban 2", list: 1 },
      { id: 2, title: "Jawaban 3", list: 1 },
    ]);

    const getList = (list) => {
      return items.value.filter((item) => item.list == list);
    };

    const startDrag = (event, item) => {
      console.log(item);
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("itemID", item.id);
    };

    const onDrop = (event, list) => {
      const itemID = event.dataTransfer.getData("itemID");
      const item = items.value.find((item) => item.id == itemID);
      if (list !== 1 && getList(list).length > 0) {
        console.log(`Hanya satu jawaban yang diizinkan untuk masuk!`);
        return;
      }
      item.list = list;
    };

    return {
      getList,
      onDrop,
      startDrag,
    };
  },
  data() {
    return {
      title: "Soal Kompetensi Menjodohkan",
      items: [
        {
          text: "Master Kompetensi",
          href: "/",
        },
        {
          text: "Kompetensi",
          href: "/",
        },
        {
          text: "Soal Kompetensi Menjodohkan",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      transactions: [
        {
          id: "#SK2540",
          name: "Neal Matthews",
          date: "07 Oct, 2019",
          total: "$400",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 1,
        },
        {
          id: "#SK2541",
          name: "Jamal Burnett",
          date: "07 Oct, 2019",
          total: "$380",
          status: "Chargeback",
          payment: ["fa-cc-visa", "Visa"],
          index: 2,
        },
        {
          id: "#SK2542",
          name: "Juan Mitchell",
          date: "06 Oct, 2019",
          total: "$384",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 3,
        },
        {
          id: "#SK2543",
          name: "Barry Dick",
          date: "05 Oct, 2019",
          total: "$412",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 4,
        },
        {
          id: "#SK2544",
          name: "Ronald Taylor",
          date: "04 Oct, 2019",
          total: "$404",
          status: "Refund",
          payment: ["fa-cc-visa", "Visa"],
          index: 5,
        },
        {
          id: "#SK2545",
          name: "Jacob Hunter",
          date: "04 Oct, 2019",
          total: "$392",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 6,
        },
      ],
      modalPreview: false,
    };
  },
  // mounted() {
  //   let self = this;
  //   self.cekAksesMenu();
  // },
  // methods: {
  //   cekAksesMenu() {
  //     let self = this;
  //     var config_axios = {
  //       method: "get",
  //       url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/check-access-menu",
  //       params: {
  //         role_id: self.sessionRoleId,
  //         link_name: self.namePathUrl,
  //       },
  //       headers: {
  //         Accept: "application/json",
  //         Authorization: "Bearer " + localStorage.access_token,
  //       },
  //     };
  //     axios(config_axios)
  //       .then((response) => {
  //         let response_data = response.data;
  //         let response_data_fix = response_data.data.status_access;
  //         if (response_data.meta.code == 200) {
  //           if (response_data_fix == false) {
  //             let timerInterval;
  //             Swal.fire({
  //               icon: "warning",
  //               title: "Oppss",
  //               text: "Mohon maaf anda tidak diberikan akses pada halaman ini.",
  //               timer: 2000,
  //               timerProgressBar: true,
  //               showCancelButton: false,
  //               showConfirmButton: false,
  //               didOpen: () => {
  //                 timerInterval = setInterval(() => {
  //                   const content = Swal.getContent();
  //                   if (content) {
  //                     const b = content.querySelector("b");
  //                     if (b) {
  //                       b.textContent = Swal.getTimerLeft();
  //                     }
  //                   }
  //                 }, 100);
  //               },
  //               willClose: () => {
  //                 clearInterval(timerInterval);
  //               },
  //             }).then((result) => {
  //               /* Read more about handling dismissals below */
  //               if (result.dismiss === Swal.DismissReason.timer) {
  //                 self.$router.push({ name: "dashboard" });
  //               }
  //             });
  //           }
  //         } else {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Oops...",
  //             text: response_data_fix.data.message,
  //           });
  //         }
  //       })
  //       .catch((errors) => {
  //         console.log(errors);
  //       });
  //   },
  // },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-lg-6">
                <div>Kompetensi Pengendalian :</div>
                <div>Pengendalian dan Evaluasi Pencemaran Limbah B3</div>
              </div>
              <div class="col-lg-6">
                <div class="float-end">
                  <button type="button" class="btn btn-success">
                    Import Soal Kompetensi
                  </button>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6">
                <div>Level Proficiency :</div>
                <div>2. Practitioner</div>
              </div>
              <div class="col-3">&nbsp;</div>
              <div class="col-3">
                <input
                  type="text"
                  class="form-control"
                  id="searchTable"
                  placeholder="Cari Data ..."
                />
              </div>
            </div>
            <div class="table-responsive mb-0">
              <table
                class="table align-middle table-nowrap table-striped text-center"
              >
                <thead class="table-light">
                  <tr>
                    <th class="align-middle">ID Soal Kompetensi</th>
                    <th class="align-middle">Nomer Uruts</th>
                    <th class="align-middle">Soal</th>
                    <th class="align-middle">Tipe</th>
                    <th class="align-middle">Gambar</th>
                    <th class="align-middle">Jumlah Pertanyaan</th>
                    <th class="align-middle">Jumlah Jawaban</th>
                    <th class="align-middle">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>2</td>
                    <td>Terdapat buah pada me</td>
                    <td>Menjodohkan</td>
                    <td>gamabar.jpg</td>
                    <td>4</td>
                    <td>4</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="modalPreview = true"
                      >
                        Preview
                      </button>
                      &nbsp;
                      <button type="button" class="btn btn-danger btn-sm">
                        Disable
                      </button>
                      <b-modal
                        v-model="modalPreview"
                        title="Preview"
                        size="lg"
                        hide-footer
                      >
                        <div><strong>Contoh Soal</strong></div>
                        <div class="bg-light">
                          <p class="p-2 mb-0">
                            Pasangkanlah lawan kata berikut?
                          </p>
                        </div>
                        <div class="row mt-2">
                          <div class="col-xl-8">
                            <div class="card">
                              <div class="card-body border">
                                <table
                                  class="table align-middle table-nowrap table-striped table-bordered font-size-14"
                                >
                                  <thead class="table-primary">
                                    <tr>
                                      <td>No</td>
                                      <td>Soal</td>
                                      <td>Jawab</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td></td>
                                      <td>
                                        <div
                                          class="drop-zone"
                                          @drop="onDrop($event, 2)"
                                          @dragenter.prevent
                                          @dragover.prevent
                                        >
                                          <div
                                            v-for="item in getList(2)"
                                            :key="item.id"
                                            class="drag-el"
                                            draggable="true"
                                            @dragstart="startDrag($event, item)"
                                          >
                                            {{ item.title }}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      <td></td>
                                      <td>
                                        <div
                                          class="drop-zone"
                                          @drop="onDrop($event, 3)"
                                          @dragenter.prevent
                                          @dragover.prevent
                                        >
                                          <div
                                            v-for="item in getList(3)"
                                            :key="item.id"
                                            class="drag-el"
                                            draggable="true"
                                            @dragstart="startDrag($event, item)"
                                          >
                                            {{ item.title }}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td></td>
                                      <td>
                                        <div
                                          class="drop-zone"
                                          @drop="onDrop($event, 4)"
                                          @dragenter.prevent
                                          @dragover.prevent
                                        >
                                          <div
                                            v-for="item in getList(4)"
                                            :key="item.id"
                                            class="drag-el"
                                            draggable="true"
                                            @dragstart="startDrag($event, item)"
                                          >
                                            {{ item.title }}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>4</td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td>5</td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div class="offset-xl-1 col-xl-3">
                            <div class="card">
                              <div class="card-body border">
                                <div
                                  class="drop-zone"
                                  @drop="onDrop($event, 1)"
                                  @dragenter.prevent
                                  @dragover.prevent
                                >
                                  <div
                                    v-for="item in getList(1)"
                                    :key="item.id"
                                    class="drag-el m-2"
                                    draggable="true"
                                    @dragstart="startDrag($event, item)"
                                  >
                                    {{ item.title }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-modal>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
<style scoped>
.drop-zone {
  background-color: #ecf0f1;
  padding: 5px;
  min-height: 30px;
  text-align: center;
}
.drag-el {
  background-color: #556ee6;
  color: white;
  padding: 5px;
}
</style>